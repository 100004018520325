import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';

import { Creators as ForgotActions, Types as ForgotTypes } from './ducks';

export function* forgot({ payload }) {
  try {
    yield call(api.post, '/auth/forgot', payload);
    yield put(ForgotActions.forgotSuccess());
    toast.success(
      'Se esse for um e-mail cadastrado no Jornada 6 em 7, você vai receber sua senha em até 5 minutos'
    );
  } catch {
    toast.error('Não foi possível fazer login');
    yield put(ForgotActions.forgotFailure(['Não foi possível fazer login.']));
  }
}

export default function* rootSaga() {
  return yield all([takeLatest(ForgotTypes.FORGOT_REQUEST, forgot)]);
}
