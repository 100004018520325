import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Creators as ModeColor } from '~/store/modules/darkMode/ducks';
import { Creators as AuthActions } from '~/store/modules/auth/ducks';
import {
  CenteredFlexBox,
  ExitButton,
  ChangeColorModeSwitcherGrid,
  LightColorModeSwitcherIcon,
  DarkColorModeSwitcherIcon,
  CustomIconButton,
  Header,
} from './styles';
import LogoPlat from '~/assets/svgs/logo.svg';

export default function ConferenceHeader() {
  const dispatch = useDispatch();
  // const paymentLink = `${process.env.REACT_APP_CENTRAL_URL
  //   }&email=${encodeURIComponent(user.email)}`;
  const handleLogout = () => {
    dispatch(AuthActions.signOut());
  };

  const { darkMode } = useSelector((state) => state.darkMode);

  const handleDarkMode = () => {
    if (darkMode) {
      dispatch(ModeColor.setLightMode());
    } else {
      dispatch(ModeColor.setDarkMode());
    }
  };

  return (
    <Header darkMode={darkMode}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <CenteredFlexBox>
            <img
              src={LogoPlat}
              alt="logo platinum"
              style={{ maxWidth: '350px' }}
            />
          </CenteredFlexBox>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={2}>
              <CenteredFlexBox>
                <ExitButton onClick={handleLogout}>Sair</ExitButton>
              </CenteredFlexBox>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <Grid container justify="center">
                <ChangeColorModeSwitcherGrid item xs={5} sm={12} lg={12}>
                  <Grid container justify="center">
                    <Grid item xs={6}>
                      <CustomIconButton
                        onClick={handleDarkMode}
                        active={!darkMode}
                      >
                        <DarkColorModeSwitcherIcon darkMode={darkMode}>
                          dark_mode
                        </DarkColorModeSwitcherIcon>
                      </CustomIconButton>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomIconButton
                        onClick={handleDarkMode}
                        active={darkMode}
                      >
                        <LightColorModeSwitcherIcon darkMode={darkMode}>
                          light_mode
                        </LightColorModeSwitcherIcon>
                      </CustomIconButton>
                    </Grid>
                  </Grid>
                </ChangeColorModeSwitcherGrid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Header>
  );
}

ConferenceHeader.propTypes = {
  vip: PropTypes.bool,
};

ConferenceHeader.defaultProps = {
  vip: false,
};
