import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  titleLaunchPlanNotFilled: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
    marginTop: 250,
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
    borderBottom: 1,
    borderBottomColor: "grey",
    borderTop: 1,
    borderTopColor: "grey",
  },
  question: {
    fontSize: 14,
    margin: 12,
    fontFamily: "Oswald",
  },
  subQuestion: {
    fontSize: 12,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 50,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footerInfo: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footerInfoCompany: {
    position: "absolute",
    fontSize: 12,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  logo: {
    position: "absolute",
    top: 25,
    left: 15,
    right: 0,
    width: "100px",
    height: "50px",
  },
});
