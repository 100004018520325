import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  darkMode: false,
  errors: [],
};

export const { Types, Creators } = createActions({
  setDarkMode: [],
  setLightMode: [],
});

function setDarkMode(state) {
  return { ...state, darkMode: true };
}
function setLightMode(state) {
  return { ...state, darkMode: false };
}

export default createReducer(INITIAL_STATE, {
  [Types.SET_DARK_MODE]: setDarkMode,
  [Types.SET_LIGHT_MODE]: setLightMode,
});
