import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Signin from '~/pages/Signin';
// import Forgot from '~/pages/Signin/Forgot';
import Lobby from '~/pages/Lobby';
import { store } from '~/store';

const ProtectedRoutes = ({ children, ...rest }) => {
  const { signed } = store.getState().user;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        signed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default function Routes() {
  return (
    <Switch>
      <Route path="/login" component={Signin}>
        <Switch>
          {/* <Route path="/login/forgot" exact component={Forgot} /> */}
          <Route path="/" component={Signin} />
        </Switch>
      </Route>
      <ProtectedRoutes path="/" exact>
        <Lobby />
      </ProtectedRoutes>
    </Switch>
  );
}
