import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { styles } from './styles';

export default function MultiAnswer({ questionItem }) {
  const temp = document.createElement('div');
  temp.innerHTML = questionItem.title;
  const title = temp.textContent || temp.innerText;

  return (
    <View>
      <Text style={styles.question}>{title}</Text>
      {questionItem.answer.length ? (
        <>
          {questionItem.answer[0].answer.items.map((question) => (
            <Text key={question.cuid} style={styles.text}>
              {question.answer}
            </Text>
          ))}
        </>
      ) : (
        <Text style={styles.text}>Não Respondido</Text>
      )}
    </View>
  );
}

MultiAnswer.propTypes = {
  questionItem: PropTypes.object.isRequired,
};
