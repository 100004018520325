import { call, put, all, takeLatest } from 'redux-saga/effects';
import api from '~/services/api';

import { Creators as PointsActions, Types as PointsTypes } from './ducks';

export function* pointRequest({ payload }) {
  try {
    const { data } = yield call(api.post, '/points', payload);
    yield put(PointsActions.success(data.point_value));
  } catch (error) {
    yield put(PointsActions.failure(error));
  }
}

export default function* rootSaga() {
  return yield all([takeLatest(PointsTypes.POINT_REQUEST, pointRequest)]);
}
