import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  loading: false,
  errors: [],
};

export const { Types, Creators } = createActions({
  signInRequest: ['payload'],
  signInSuccess: [],
  signInFailure: ['errors'],
  signOut: [],
});

function signInRequest(state) {
  return { ...state, loading: true };
}

function signInSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function signInFailure(state, { errors }) {
  return {
    ...state,
    loading: false,
    errors,
  };
}

function signOut(state) {
  return { ...state, ...INITIAL_STATE };
}

export default createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,
  [Types.SIGN_OUT]: signOut,
});
