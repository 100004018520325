import { Grid } from '@material-ui/core';
import React from 'react';
import {
  ChangeColorModeSwitcherGrid,
  CustomIconButton,
  DarkColorModeSwitcherIcon,
  LightColorModeSwitcherIcon,
} from './styles';

export default function DarkModeSwitch({ handleDarkMode, darkMode }) {
  return (
    <Grid item xs={12} sm={2} lg={1}>
      <Grid container justify="center">
        <ChangeColorModeSwitcherGrid
          darkMode={darkMode}
          item
          xs={5}
          sm={12}
          lg={12}
        >
          <Grid container justify="center">
            <Grid item xs={6}>
              <CustomIconButton onClick={handleDarkMode} active={!darkMode}>
                <DarkColorModeSwitcherIcon darkMode={darkMode}>
                  dark_mode
                </DarkColorModeSwitcherIcon>
              </CustomIconButton>
            </Grid>
            <Grid item xs={6}>
              <CustomIconButton onClick={handleDarkMode} active={darkMode}>
                <LightColorModeSwitcherIcon darkMode={darkMode}>
                  light_mode
                </LightColorModeSwitcherIcon>
              </CustomIconButton>
            </Grid>
          </Grid>
        </ChangeColorModeSwitcherGrid>
      </Grid>
    </Grid>
  );
}
