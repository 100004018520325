import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import logo from '~/assets/svgs/logo.svg';

export default function ConferenceFooter() {
  return (
    <Box
      width={1}
      display="flex"
      alignText="center"
      justifyContent="center"
      alignItems="center"
    >
      <img src={logo} alt="logo footer" style={{ maxWidth: '300px' }} />
    </Box>
  );
}
