import axios from 'axios';

const getToken = () => {
  const persistKey = 'persist:confclient';
  const persistedObject = JSON.parse(localStorage.getItem(persistKey)) || '{}';
  const user = persistedObject.user || '{}';
  const { token } = JSON.parse(user);
  return !!token ? token : '';
};

export default axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});
