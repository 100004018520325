import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { CloudDownloadOutlined } from '@material-ui/icons';
import { FileInputDiv, QuestionTitle, CustomButton } from './styles';
import { ReactComponent as UploadSvg } from '~/assets/svgs/upload.svg';

export default function FileQuestion({
  questionItem,
  handleSubmitAnswer,
  textSubmitButton,
  answerItem,
  loading,
}) {
  const fileInput = createRef();
  const [file, setFile] = useState(null);

  const handleDivClick = () => {
    fileInput.current.click();
  };

  const handleChange = (event) => {
    const [uploadedFile] = event.target.files;

    const fileTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'application/pdf',
    ];

    if (
      !fileTypes.includes(uploadedFile.type) ||
      uploadedFile.size > 10 * 1024 * 1024
    ) {
      return toast.error('Tipo de arquivo inválido ou tamanho maior que 10mb');
    }
    return setFile(uploadedFile);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('questionItemId', questionItem.id);
    const formValues = {
      formData,
      evidence: true,
    };
    return handleSubmitAnswer(formValues);
  };

  const handleDownloadFile = () => {
    window.open(answerItem.answer.choice, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box>
      <Grid container>
        <Box mb={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Box mb={3}>
              <QuestionTitle>{questionItem.title}</QuestionTitle>
            </Box>
          </Grid>
          {answerItem && answerItem.answer ? (
            <Grid item xs={12} sm={12} md={12}>
              <FileInputDiv onClick={() => handleDownloadFile()}>
                <Grid container>
                  <Box p={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        mb={1}
                      >
                        <CloudDownloadOutlined fontSize="large" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box width="100%" display="flex" justifyContent="center">
                        <Typography
                          variant="body2"
                          align="center"
                          style={{
                            color: '#353535',
                            fontSize: '0.9rem',
                            fontWeight: 700,
                            lineHeight: '1.2',
                          }}
                        >
                          Download do arquivo enviado
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </FileInputDiv>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <FileInputDiv onClick={handleDivClick}>
                <Grid container>
                  <Box p={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        mb={1}
                      >
                        <UploadSvg
                          style={{ height: '5.5rem', fill: '#d0d0d0' }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box width="100%" display="flex" justifyContent="center">
                        <Typography
                          variant="body2"
                          align="center"
                          style={{
                            color: '#353535',
                            fontSize: '0.9rem',
                            fontWeight: 700,
                            lineHeight: '1.2',
                          }}
                        >
                          Faça o upload do seu arquivo aqui
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        align="center"
                        style={{
                          color: '#353535',
                          fontSize: '0.8rem',
                          fontWeight: 700,
                          lineHeight: '1.2',
                          paddingTop: '10px',
                        }}
                      >
                        Tipos de arquivos válidos: JPG, JPEG, PNG, PDF
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </FileInputDiv>

              <input
                ref={fileInput}
                onChange={(e) => handleChange(e)}
                type="file"
                name="fileName"
                hidden
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            {file && (
              <span style={{ fontSize: '0.9rem', lineHeight: '1' }}>
                Arquivo anexado: <b>{file.name}</b>
              </span>
            )}
          </Grid>
        </Box>
        <Grid item xs={12} sm={12} md={12}>
          <CustomButton
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading || !file || answerItem}
          >
            {textSubmitButton}
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
}

FileQuestion.propTypes = {
  questionItem: PropTypes.object.isRequired,
  answerItem: PropTypes.object.isRequired,
  handleSubmitAnswer: PropTypes.func.isRequired,
  textSubmitButton: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
