import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { WarmupRoomsPaper, StyledBox } from './styles';
import StandardModal from '~/pages/Lobby/components/Modal';

import { Creators as ModalActions } from '~/store/modules/modal/ducks';

const MODAL_NAME = 'CONFERENCE_MODAL';
export default function WarmupRoomsComponent({ warmupRooms = [] }) {
  const dispatch = useDispatch();

  const active = useSelector((state) => state.modal.active);
  const handleClose = () => {
    dispatch(ModalActions.closeModal());
  };

  return (
    <StandardModal
      isOpened={active === MODAL_NAME}
      onClose={handleClose}
      showDividers={false}
      maxWidth="lg"
      fullScreen={window.innerWidth <= 600}
    >
      <StyledBox>
        <Box width={1} textAlign="center">
          <Box mb={2}>
            <Typography variant="h3" style={{ fontWeight: 600 }}>
              ESTAMOS AO VIVO COM O
            </Typography>
            <Typography
              variant="h3"
              style={{ color: '#2196f3', fontWeight: 600 }}
            >
              AQUECIMENTO DA JORNADA 6 em 7
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography
              variant="body2"
              style={{ color: '#4e4e4e', fontWeight: 500 }}
            >
              Navegue entre essas salas, tire suas dúvidas e interaja com os
              convidados antes da aula começar.
            </Typography>
          </Box>
        </Box>
        <Box width={1}>
          <Grid container spacing={2}>
            {warmupRooms.map((warmupRoom) => (
              <Grid item xs={12} sm={6} md={6} key={warmupRoom.cuid}>
                <WarmupRoomsPaper
                  elevation={0}
                  variant="outlined"
                  style={{ minHeight: '300px' }}
                >
                  <Box
                    display="flex"
                    p={3}
                    flexDirection="column"
                    justifyContent="flex-start"
                  >
                    <Box pb={1}>
                      <Typography variant="h2">{warmupRoom.name}</Typography>
                    </Box>
                    <Box pb={3}>
                      <Typography
                        variant="h6"
                        style={{ color: '#4e4e4e', fontWeight: 600 }}
                      >
                        Convidado: {warmupRoom.speaker}
                      </Typography>
                    </Box>
                    <Box pb={4}>
                      <Typography
                        variant="body2"
                        style={{ color: '#4e4e4e', fontWeight: 500 }}
                      >
                        {warmupRoom.description}
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          color: '#fff',
                          fontWeight: 600,
                          padding: '.5rem 2rem',
                        }}
                        href={warmupRoom.link}
                        target="_blank"
                      >
                        ENTRAR NA SALA
                      </Button>
                    </Box>
                  </Box>
                </WarmupRoomsPaper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </StyledBox>
    </StandardModal>
  );
}

WarmupRoomsComponent.propTypes = {
  warmupRooms: PropTypes.array.isRequired,
};
