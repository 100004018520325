import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  Types as AnswerTypes,
  Creators as AnswerActions,
} from '~/store/modules/answer/ducks';
import api from '~/services/api';

export function* fetchAnswers() {
  try {
    const { data } = yield call(api.get, '/answers');
    yield put(AnswerActions.answersListRequestSuccess(data.answers));
  } catch {
    toast.error('Erro ao carregar respostas');
    yield put(AnswerActions.answersListRequestFailure());
  }
}

export function* saveAnswer({ payload }) {
  try {
    let formData = payload;
    let url = '/answers';
    if (payload.evidence) {
      formData = payload.formData;
      url = '/answers/evidence';
    }
    yield call(api.post, url, formData);
    yield put(AnswerActions.answersListRequestSuccess());
    yield fetchAnswers();
    toast.success('Resposta salva com sucesso');
  } catch ({ response }) {
    let error =
      'Não foi possivel salvar a resposta, tente novamente mais tarde';
    if (response.status === 400) {
      error = response.data.message;
    }
    toast.error(error);
    yield put(AnswerActions.answerSaveRequestFailure());
  }
}

export function* saveLaunchAnswer({ payload }) {
  try {
    yield call(api.post, '/launchPlanAnswers', payload);
    yield put(AnswerActions.answersListRequestSuccess());

    toast.success('Resposta salva com sucesso');
  } catch (error) {
    toast.error('Não foi possível salvar sua resposta.');
    yield put(AnswerActions.answerSaveRequestFailure());
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(AnswerTypes.ANSWERS_LIST_REQUEST, fetchAnswers),
    takeLatest(AnswerTypes.ANSWER_SAVE_REQUEST, saveAnswer),
    takeLatest(AnswerTypes.LAUNCH_ANSWER_REQUEST, saveLaunchAnswer),
  ]);
}
