import styled from 'styled-components';
import { Button, Dialog, DialogTitle, Grid } from '@material-ui/core';

export const StyledDialog = styled(Dialog)`
  .forgotMyToken {
    color: ${(props) => (props.darkMode ? '#efefee' : '#5b5b5b')} !important;
  }
`;

export const StyledCloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  .MuiTypography-subtitle1 {
    font-size: 16px;
    font-weight: 700;
    color: #2e3133;
  }
`;

export const InputGrid = styled(Grid)`
  .inputDarkLightMode {
    background-color: ${(props) => (props.darkMode ? '#787878' : '#e0e0e0')};
    border: 0;

    input {
      color: ${(props) => (props.darkMode ? '#efefee' : '#5b5b5b')};
      font-weight: bold;
      .MuiIconButton-root {
        color: ${(props) => (props.darkMode ? '#efefee' : '#5b5b5b')};
      }
    }
  }

  p {
    background-color: ${(props) => (props.darkMode ? '#848483' : '#cccccc')};
    font-size: 16px;
    color: ${(props) => (props.darkMode ? '#efefee' : '#5b5b5b')};
    margin: 0;
  }
`;

export const SubmitButton = styled(Button)`
  background-color: ${(props) => (props.darkMode ? '#787878' : '#e0e0e0')};
  color: ${(props) => (props.darkMode ? '#efefee' : '#5b5b5b')};
  &:hover {
    background-color: ${(props) => (props.darkMode ? '#272523' : '#afafaf')};
  }
`;
