import api from './api';
import { store } from '~/store';
import { Creators as AuthActions } from '~/store/modules/auth/ducks';

const onExpireToken = () => {
  api.interceptors.response.use(
    response => response,
    error => {
      const status = error.response ? error.response.status : error.status;
      const errorMessage = error.message || '';
      const unauthorizedPattern = /status code 401/i;
      const { token, signed } = store.getState().user;

      if (!token || !signed) {
        return;
      }

      if (status === 401 || errorMessage.match(unauthorizedPattern)) {
        store.dispatch(AuthActions.signOut());
        return Promise.reject();
      }

      return Promise.reject(error);
    }
  );
};

export default {
  onExpireToken,
};
