import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  Button,
  Box,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { BlobProvider } from '@react-pdf/renderer';
import { CustomAccordionDetails } from './styles';
import MultiAnswer from './components/multiAnswer';
import MultiChoice from './components/multiChoice';
import SingleChoice from './components/singleChoice';

import { Creators as AnswerActions } from '~/store/modules/answer/ducks';
import { Creators as PointsActions } from '~/store/modules/points/ducks';
import { Creators as ConferenceActions } from '~/store/modules/conference/ducks';
import LaunchPlanPDF from './PDF';

export default function LaunchPlan({ components, pdfComponent }) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const userProfile = useSelector((state) => state.user.profile);

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSubmitAnswer = (fields) => {
    dispatch(AnswerActions.launchAnswerRequest(fields));
    dispatch(
      ConferenceActions.saveLaunchPlanAnswer({
        componentId: fields.componentId,
        answer: fields.answer,
        itemId: fields.launchPlanItemId,
      })
    );
  };

  const renderLaunchItem = (item, componentId) => {
    const options = {
      MultiAnswer: (
        <MultiAnswer
          launchItem={item}
          handleSubmitAnswer={handleSubmitAnswer}
          answerItem={item.answer}
          componentId={componentId}
        />
      ),
      MultiChoice: (
        <MultiChoice
          launchItem={item}
          handleSubmitAnswer={handleSubmitAnswer}
          answerItem={item.answer}
          componentId={componentId}
        />
      ),
      SingleChoice: (
        <SingleChoice
          launchItem={item}
          handleSubmitAnswer={handleSubmitAnswer}
          answerItem={item.answer}
          componentId={componentId}
        />
      ),
    };

    return options[item.kind];
  };

  const handlePDFDownload = (pdf, componentId) => {
    dispatch(PointsActions.pointRequest({ componentId, kind: 'component' }));
    window.open(URL.createObjectURL(pdf), '_blank', 'noopener noreferrer');
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {!!pdfComponent && (
          <BlobProvider
            document={
              <LaunchPlanPDF launch={components} userProfile={userProfile} />
            }
          >
            {({ blob, loading }) => {
              return (
                <>
                  {loading ? (
                    <Box mt={2} mb={4} textAlign="center">
                      <Typography>
                        Estamos carregando o seu plano de lançamento...
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <Box mt={2} mb={4} textAlign="center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handlePDFDownload(blob, pdfComponent.id)
                          }
                        >
                          {pdfComponent.sale_letter.cta_text}
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              );
            }}
          </BlobProvider>
        )}
        {components.length ? (
          <>
            {components.map((component) => (
              <Accordion
                expanded={expanded === component.id}
                onChange={handlePanelChange(component.id)}
                elevation={0}
                key={component.id}
                style={{
                  color: '#333637',
                  border: '1px solid rgba(0, 0, 0, .125)',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)' }}
                >
                  <Typography variant="h6">
                    {component.launch_plan.title}
                  </Typography>
                </AccordionSummary>
                <CustomAccordionDetails>
                  <Grid container>
                    {!!component.launch_plan.items.length &&
                      component.launch_plan.items.map((item) => (
                        <Grid item xs={12} key={item.id}>
                          {renderLaunchItem(item, component.id)}
                        </Grid>
                      ))}
                  </Grid>
                </CustomAccordionDetails>
              </Accordion>
            ))}
          </>
        ) : (
          <Box mt={3} textAlign="center">
            <Typography>Nenhum Plano de lançamento ativo no momento</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

LaunchPlan.propTypes = {
  components: PropTypes.array.isRequired,
  pdfComponent: PropTypes.array.isRequired,
};
