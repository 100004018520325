import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, RadioGroup, FormControlLabel } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { CustomRadio } from '~/pages/Lobby/components/Questions/styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CustomButton } from './styles';

export default function SingleChoiceQuestion({
  launchItem,
  handleSubmitAnswer,
  answerItem,
  componentId,
}) {
  const { choices = [] } = launchItem.details;
  const [userChoice, setUserChoice] = useState('');

  useEffect(() => {
    if (answerItem.length) {
      const { answer } = answerItem[0];
      setUserChoice(answer.item);
    }
  }, [answerItem]);

  const handleChange = (event) => {
    setUserChoice(event.target.value);
  };

  const getEditorTextFormated = (content) => {
    const conferenceKeyValue = htmlToDraft(content);
    const editorState = ContentState.createFromBlockArray(
      conferenceKeyValue.contentBlocks
    );

    return EditorState.createWithContent(editorState);
  };
  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Box>
            <Editor
              toolbarHidden
              editorState={getEditorTextFormated(launchItem.title)}
              editorStyle={{ pointerEvents: 'none' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box mb={2}>
            <RadioGroup
              name="choice"
              value={userChoice}
              onChange={(e) => handleChange(e)}
            >
              {choices.map((choice) => (
                <FormControlLabel
                  key={choice.cuid}
                  value={choice.cuid}
                  control={<CustomRadio />}
                  label={choice.label}
                />
              ))}
            </RadioGroup>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomButton
            variant="contained"
            color="secondary"
            onClick={() =>
              handleSubmitAnswer({
                launchPlanItemId: launchItem.id,
                pointValue: launchItem.point_value,
                answer: { item: userChoice },
                componentId,
              })
            }
          >
            SALVAR
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
}

SingleChoiceQuestion.propTypes = {
  launchItem: PropTypes.object.isRequired,
  handleSubmitAnswer: PropTypes.func.isRequired,
  answerItem: PropTypes.object.isRequired,
  componentId: PropTypes.number.isRequired,
};
