import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  active: '',
  params: {},
};

export const { Types, Creators } = createActions({
  openModal: ['payload'],
  closeModal: ['payload'],
});

function openModal(state, { payload }) {
  if (typeof payload === 'object') {
    return {
      ...state,
      active: payload.name,
      params: payload.params,
    };
  }

  return {
    ...state,
    active: payload,
    params: {},
  };
}

function closeModal(state) {
  return {
    ...state,
    active: '',
    params: {},
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.OPEN_MODAL]: openModal,
  [Types.CLOSE_MODAL]: closeModal,
});
