import styled from 'styled-components';
import { Button, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const FileInputDiv = styled.div`
  background: #fff;
  width: 12rem;
  border: 2px dashed #abaaab;
  border-radius: 20px;

  &:hover {
    cursor: pointer;
  }
`;

export const QuestionTitle = styled.h2`
  color: #353535;
  font-size: 27px;
  line-height: 27px;
`;

export const CustomRadio = styled(Radio)`
  &.MuiRadio-root {
    color: #201b02;
  }
`;

export const CustomButton = styled(Button)`
  font-weight: 700;
  padding: 0.5rem 1.5rem;
  min-width: 110px;
  color: white;
`;

export const TextFieldCss = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: '#353535',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#f2f2f2',
      marginBottom: '1rem',
      color: '#353535',
      '&.Mui-focused fieldset, &:hover fieldset': {
        borderColor: '#353535',
      },
    },
  },
});
