import React, { useEffect } from 'react';
import io from 'socket.io-client';
import copy from 'copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Tabs, Typography } from '@material-ui/core';
import {
  Assignment,
  Cached,
  DateRange,
  EmojiEvents,
  GroupAdd,
  Info,
  NearMe,
  PlayArrow,
} from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';
import Icon from '@material-ui/core/Icon';
import Header from './header';
import { Creators as ModalActions } from '~/store/modules/modal/ducks';
import FileQuestion from './components/Questions/file';
import MultiAnswerQuestion from './components/Questions/multiAnswer';
import MultiChoiceQuestion from './components/Questions/multiChoice';
import SingleChoiceQuestion from './components/Questions/singleChoice';
import InstructionsComponent from './components/Instructions';
import LaunchPlanComponent from './components/LaunchPlan';
import {
  WelcomeText,
  EventButton,
  SideBar,
  MainContent,
  HrBarGrid,
  StyledQuestionDate,
  CenteredGrid,
  TabButton,
  SideButton,
  SideBox,
  GridWelcome,
  EventButtonBox,
  GridEventButton,
  BackgroundBox,
  FooterGrid,
} from './styles';
import PointsToast from '~/components/PointsToast';
import Resources from '~/pages/Lobby/components/Resources';
import { Creators as ConferenceActions } from '~/store/modules/conference/ducks';
import { Creators as AnswerActions } from '~/store/modules/answer/ducks';
import { Creators as PointsActions } from '~/store/modules/points/ducks';
import { Creators as PresencesAction } from '~/store/modules/presence/ducks';
import CopyAffiliateLinkModal from '~/pages/Lobby/components/CopyAffiliateLinkModal';
import UpgradedModal from '~/pages/Lobby/components/UpgradedModal';
import WarmupRoomsModal from '~pages/Lobby/components/WarmupRooms';
import UserRanking from '~/pages/Lobby/components/UserRanking';
import { Creators as AuthActions } from '~/store/modules/auth/ducks';
import Countdown from '~/pages/Lobby/components/Countdown';
import Footer from './footer';

const TOGGLE_COMPONENT_EVENT = 'toggle';
const SOCKET_SERVER_URL = process.env.REACT_APP_API;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
};

export default function ManageConference() {
  const dispatch = useDispatch();
  const COPY_AFFILIATE_LINK_MODAL = 'COPY_AFFILIATE_LINK_MODAL';
  const UPGRADE_MODAL = 'UPGRADE_MODAL';
  const conference = useSelector((state) => state.conference);
  const { darkMode } = useSelector((state) => state.darkMode);
  const { answers } = useSelector((state) => state.answers);
  const { components, current: conferenceConfig } = conference;
  let countdownComponentId = 0;
  let pdfComponent = null;
  const [tabValue, setTabValue] = React.useState(0);
  const activeModal = useSelector((state) => state.modal.active);
  const questionLoading = useSelector((state) => state.answers.loading);

  const resourcesLists =
    components.filter((item) => !!item.resourceId && item.active) || [];

  const affiliateComponent =
    components.find((item) => item.affiliate_button && item.active) || null;

  const questionsComponents = components.filter(
    (component) =>
      component.active &&
      !!component.question &&
      !!component.question.items.length
  );

  const sideMenuButtons = components.filter(
    (component) => component.active && component.side_button
  );

  const buttonsList = components.filter(
    (component) =>
      component.active &&
      !component.side_button &&
      component.saleLetterId &&
      !component.pdf_button
  );

  const launchPlanModules = components.filter((component) => {
    if (component.pdf_button && component.active) {
      pdfComponent = component;
    }

    return component.launchPlanId && !component.pdf_button && component.active;
  });

  const componentConferenceButton = components.find(
    (component) => component.active && !!component.conferenceButtonId
  );

  const warmupRoomComponent = components.find(
    (component) => component.active && !!component.warmupRoomId
  );

  const user = useSelector((state) => state.user.profile);

  const getQuestionItemAnswer = (questionItemId) => {
    if (answers) {
      return answers.find((item) => {
        return item.question_item_id === questionItemId;
      });
    }
    return null;
  };

  const saveAnswer = (answer) => {
    dispatch(AnswerActions.answerSaveRequest(answer));
  };

  const handlePointsToast = (points) => {
    return toast(<PointsToast points={points} />);
  };

  useEffect(() => {
    dispatch(AnswerActions.answersListRequest({ userId: user.id }));
    dispatch(ConferenceActions.componentsRequest());
    dispatch(ConferenceActions.fetchConferenceConfigRequest());
  }, [dispatch]);

  useEffect(() => {
    const socketClient = io.connect(SOCKET_SERVER_URL);
    socketClient.on(TOGGLE_COMPONENT_EVENT, ({ id, active, featured }) => {
      dispatch(ConferenceActions.componentToggle({ id, active }));
    });

    socketClient.on('connect', () => {
      socketClient.emit('join', { id: user.id });
    });

    socketClient.on('points', (data) => {
      handlePointsToast(`${data.points} pontos`);
    });

    socketClient.on('upgrade', (data) => {
      const { tag } = data;
      dispatch(
        ModalActions.openModal({
          name: UPGRADE_MODAL,
          params: { tag },
        })
      );
    });
  }, [dispatch]);

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  if (components.length) {
    components.map((component) => {
      if (component.countdownId) {
        countdownComponentId = component.active ? component.countdownId : 0;
      }
    });
  }

  const renderQuestionComponents = (questionItem) => {
    const answerItem = getQuestionItemAnswer(questionItem.id);
    const textSubmitButton = answerItem ? 'RESPONDIDO' : 'ENVIAR';
    const componentsList = {
      Evidence: (
        <FileQuestion
          questionItem={questionItem}
          handleSubmitAnswer={saveAnswer}
          textSubmitButton={textSubmitButton}
          answerItem={answerItem}
          loading={questionLoading}
        />
      ),
      MultiAnswer: (
        <MultiAnswerQuestion
          questionItem={questionItem}
          handleSubmitAnswer={saveAnswer}
          textSubmitButton={textSubmitButton}
          answerItem={answerItem}
          loading={questionLoading}
        />
      ),
      SingleChoice: (
        <SingleChoiceQuestion
          questionItem={questionItem}
          handleSubmitAnswer={saveAnswer}
          textSubmitButton={textSubmitButton}
          answerItem={answerItem}
          loading={questionLoading}
        />
      ),
      MultiChoice: (
        <MultiChoiceQuestion
          questionItem={questionItem}
          handleSubmitAnswer={saveAnswer}
          textSubmitButton={textSubmitButton}
          answerItem={answerItem}
          loading={questionLoading}
        />
      ),
    };

    return componentsList[questionItem.kind]
      ? componentsList[questionItem.kind]
      : '';
  };

  const formatQuestionDate = (date) => {
    return format(parseISO(date), `dd 'de' MMMM 'às 'HH mm`, {
      locale: ptBRLocale,
    });
  };

  const handlePointClick = (componentId, kind = 'component') => {
    dispatch(PointsActions.pointRequest({ componentId, kind }));
  };

  const handleEventButtonClick = () => {
    if (componentConferenceButton.point_on_click) {
      handlePointClick(componentConferenceButton.id);
    }
    dispatch(
      PresencesAction.presenceRequest({
        componentId: componentConferenceButton.id,
      })
    );
  };

  const handleOpenAffiliateLinkModal = () => {
    dispatch(ModalActions.openModal({ name: COPY_AFFILIATE_LINK_MODAL }));
  };

  const handleLogout = () => {
    dispatch(AuthActions.signOut());
  };

  const handleCloseModal = () => {
    dispatch(ModalActions.closeModal());
  };

  const handleCopy = () => {
    copy(user.affiliate_link);
    toast.success('Link copiado com sucesso.');
    handleCloseModal();
  };

  const welcomeColor = () => {
    if (darkMode) {
      return '#ffffff';
    }
    return '#000000';
  };

  return (
    <>
      <BackgroundBox width={1} darkMode={darkMode}>
        {Object.keys(user).length && (
          <>
            <Header vip={user.vip} />
            {!!affiliateComponent &&
              activeModal === COPY_AFFILIATE_LINK_MODAL && (
                <CopyAffiliateLinkModal
                  link={user.affiliate_link}
                  handleCopy={handleCopy}
                  handleCloseModal={handleCloseModal}
                />
              )}

            {activeModal === UPGRADE_MODAL && (
              <UpgradedModal handleLogout={handleLogout} />
            )}
            <GridWelcome container justify="start">
              <Grid item sm={12}>
                <WelcomeText variant="h5" style={{ color: welcomeColor() }}>
                  Bem-Vindo(a), <b>{user.name}</b>
                </WelcomeText>
              </Grid>
              {/* <Grid item sm={2}>
              <WelcomeText variant="h5" style={{ color: welcomeColor() }}>
                FAIXA BRANCA
              </WelcomeText>
            </Grid> */}
            </GridWelcome>
            <GridEventButton container alignItems="flex-end">
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                style={{ 'margin-bottom': '10px' }}
              >
                {!!componentConferenceButton && (
                  <Box mb={2}>
                    <EventButton
                      variant="contained"
                      className="conferenceButton"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        user.conference_link ||
                        componentConferenceButton.conference_button
                          .button_fallback_link
                      }
                      onClick={() => handleEventButtonClick()}
                    >
                      {componentConferenceButton.conference_button.button_text}
                    </EventButton>
                  </Box>
                )}

                {!!warmupRoomComponent && (
                  <Box my={2}>
                    <EventButton
                      variant="contained"
                      color="primary"
                      className="conferenceButton"
                      onClick={() =>
                        dispatch(ModalActions.openModal('CONFERENCE_MODAL'))
                      }
                    >
                      ESTAMOS AO VIVO COM O AQUECIMENTO DA JORNADA 6 em 7
                    </EventButton>
                  </Box>
                )}

                {buttonsList.map((buttonComponent) => (
                  <Box mb={2} key={buttonComponent.id}>
                    <EventButton
                      variant="contained"
                      color="primary"
                      className="conferenceButton"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={buttonComponent.sale_letter.cta_link}
                      onClick={() =>
                        buttonComponent.point_on_click
                          ? handlePointClick(buttonComponent.id)
                          : ''
                      }
                    >
                      {buttonComponent.sale_letter.cta_text}
                    </EventButton>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    {!!countdownComponentId && (
                      <Countdown id={countdownComponentId} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </GridEventButton>
            <CenteredGrid
              container
              justify="center"
              style={{ padding: '1rem' }}
            >
              <MainContent
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                darkMode={darkMode}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  style={{ paddingTop: '1rem' }}
                >
                  <TabButton
                    display
                    label={
                      <div className="tab-icon">
                        <Info />
                        <span>INFORMAÇÕES DO EVENTO</span>
                      </div>
                    }
                  />
                  <TabButton
                    display={false}
                    label={
                      <div className="tab-icon">
                        <Cached />
                        <span>ATIVIDADES</span>
                      </div>
                    }
                  />
                  <TabButton
                    display={false}
                    label={
                      <div className="tab-icon">
                        <Assignment />
                        <span>MATERIAIS</span>
                      </div>
                    }
                  />
                  <TabButton
                    display={conferenceConfig.pointEnabled}
                    label={
                      <div className="tab-icon">
                        <EmojiEvents />
                        <span>SUA PONTUAÇÃO</span>
                      </div>
                    }
                  />
                  <TabButton
                    display={false}
                    label={
                      <div className="tab-icon">
                        <NearMe />
                        <span>PLANO PARA O 6 EM 7</span>
                      </div>
                    }
                  />
                </Tabs>
                <HrBarGrid>
                  <hr width="90%" />
                </HrBarGrid>
                <TabPanel
                  value={tabValue}
                  index={0}
                  className="tabPanelDarkMode"
                >
                  <InstructionsComponent
                    conferenceConfig={{
                      faq: conferenceConfig.faq || '',
                      instructions: conferenceConfig.instructions || '',
                    }}
                  />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={1}
                  className="tabPanelDarkMode"
                >
                  <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={12}>
                      <>
                        {questionsComponents.length ? (
                          <>
                            {questionsComponents.map((component) => (
                              <Grid key={component.id}>
                                <Typography component="span" variant="h3">
                                  <b>{component.name}</b>
                                </Typography>
                                <Box pt={3}>
                                  <StyledQuestionDate variant="body2">
                                    <b>
                                      {formatQuestionDate(component.updatedAt)}
                                    </b>
                                  </StyledQuestionDate>
                                </Box>
                                <Box pt={3}>
                                  {component.question.items.map(
                                    (questionItem) => (
                                      <Box key={questionItem.id} mb={6}>
                                        {renderQuestionComponents(questionItem)}
                                      </Box>
                                    )
                                  )}
                                </Box>
                                <hr />
                              </Grid>
                            ))}
                          </>
                        ) : (
                          <Box mt={3} textAlign="center">
                            <Typography>
                              Nenhuma atividade ativa no momento
                            </Typography>
                          </Box>
                        )}
                      </>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={2}
                  className="tabPanelDarkMode"
                >
                  <Resources
                    resourcesListsComponents={resourcesLists}
                    handlePointClick={handlePointClick}
                  />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={4}
                  className="tabPanelDarkMode"
                >
                  <LaunchPlanComponent
                    components={launchPlanModules}
                    pdfComponent={pdfComponent}
                  />
                </TabPanel>
                {!!conferenceConfig.pointEnabled && (
                  <TabPanel
                    value={tabValue}
                    index={3}
                    className="tabPanelDarkMode"
                  >
                    <UserRanking />
                  </TabPanel>
                )}
              </MainContent>
              <SideBar item xs={12} sm={12} md={4} lg={4}>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems={window.innerWidth >= 960 ? 'flex-end' : 'center'}
                >
                  {sideMenuButtons.map((sideButton) => (
                    <Grid item key={sideButton.id}>
                      <SideButton
                        target="_blank"
                        href={sideButton.sale_letter.cta_link}
                        rel="noopener noreferrer"
                        variant="contained"
                        color="primary"
                        bgcolor={sideButton.sale_letter.background_color}
                        iconcolor={sideButton.sale_letter.icon_color}
                        fontcolor={sideButton.sale_letter.text_color}
                        hvrcolor={sideButton.sale_letter.background_color}
                        onClick={() =>
                          sideButton.point_on_click
                            ? handlePointClick(sideButton.id)
                            : ''
                        }
                        startIcon={<Icon>{sideButton.sale_letter.icon}</Icon>}
                      >
                        {sideButton.sale_letter.cta_text.toUpperCase()}
                      </SideButton>
                    </Grid>
                  ))}
                  {!!affiliateComponent && (
                    <Grid item>
                      <SideButton
                        onClick={handleOpenAffiliateLinkModal}
                        variant="contained"
                        color="primary"
                        startIcon={<GroupAdd />}
                      >
                        CONVIDE UM AMIGO
                      </SideButton>
                    </Grid>
                  )}
                  <Grid item>
                    <span style={{ pointerEvents: 'none' }}>
                      <SideButton
                        variant="contained"
                        color="primary"
                        startIcon={<DateRange />}
                      >
                        CRONOGRAMA
                      </SideButton>
                    </span>
                    <SideBox>
                      <Box
                        width={1}
                        py={1}
                        dangerouslySetInnerHTML={{
                          __html: conferenceConfig.schedule,
                        }}
                      />
                    </SideBox>
                  </Grid>
                </Grid>
              </SideBar>
            </CenteredGrid>
          </>
        )}
        {!!warmupRoomComponent && (
          <WarmupRoomsModal
            warmupRooms={warmupRoomComponent.warmup_room.list}
          />
        )}
      </BackgroundBox>
      <FooterGrid darkMode={darkMode}>
        <Footer />
      </FooterGrid>
    </>
  );
}
