import React from 'react';
import PropTypes from 'prop-types';

import { Close } from '@material-ui/icons';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  StyledCloseIcon,
  StyledDialogTitle,
} from '~/pages/Lobby/components/Modal/styles';

export default function StandardModal({
  actionElements,
  children,
  isOpened,
  onClose,
  showDividers,
  title,
  maxWidth,
  blockBackdrop,
  hideCloseButton,
  fullScreen,
  fullWidth,
  scroll,
  disableEnforceFocus,
}) {
  return (
    <Dialog
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpened}
      disableEnforceFocus={disableEnforceFocus}
      PaperProps={{
        style: {
          backgroundColor: '#fff',
          boxShadow: 'true',
        },
      }}
      disableBackdropClick={blockBackdrop}
      disableEscapeKeyDown={blockBackdrop}
      scroll={scroll}
    >
      <StyledDialogTitle
        PaperProps={{
          style: {
            backgroundColor: '#fff',
          },
        }}
        onClose={onClose}
        align="center"
      >
        <Typography variant="subtitle1" component="span">
          {title}
        </Typography>
        {!hideCloseButton ? (
          <StyledCloseIcon>
            <IconButton aria-label="close" onClick={onClose}>
              <Close size="18" />
            </IconButton>
          </StyledCloseIcon>
        ) : null}
      </StyledDialogTitle>
      <DialogContent dividers={showDividers}>{children}</DialogContent>
      <DialogActions>{actionElements}</DialogActions>
    </Dialog>
  );
}

StandardModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  onClose: PropTypes.func,
  actionElements: PropTypes.element,
  isOpened: PropTypes.bool,
  showDividers: PropTypes.bool,
  maxWidth: PropTypes.string,
  blockBackdrop: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  scroll: PropTypes.string,
  disableEnforceFocus: PropTypes.bool,
};

StandardModal.defaultProps = {
  children: null,
  title: '',
  onClose: () => ({}),
  actionElements: null,
  isOpened: false,
  showDividers: true,
  maxWidth: 'md',
  blockBackdrop: false,
  hideCloseButton: false,
  fullScreen: false,
  fullWidth: false,
  scroll: 'body',
  disableEnforceFocus: false,
};
