import styled from 'styled-components';
import { Button, TextField } from '@material-ui/core';

export const StyledButtonCancel = styled(Button)`
  color: #2e3133;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.75;
  text-transform: none;
`;

export const StyledButtonCopy = styled(Button)`
  background-color: #00a574;

  color: #ffffff;
  :hover {
    background-color: #004e45;
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    background-color: #f2f2f2;
    margin-bottom: 1rem;
    color: #353535;
    &.Mui-focused fieldset,
    &:hover fieldset {
      border-color: #353535;
    }
  }
`;
