import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { QuestionTitle, CustomButton } from './styles';

export default function MultiChoiceQuestion({
  questionItem,
  handleSubmitAnswer,
  answerItem,
  textSubmitButton,
  loading,
}) {
  const { choices = [] } = questionItem.details;
  const [userChoices, setUserChoices] = useState([]);

  useEffect(() => {
    if (answerItem) {
      const { answer } = answerItem;
      setUserChoices(answer.items);
    }
  }, [answerItem]);

  const handleChange = (cuid) => {
    if (userChoices.includes(cuid)) {
      const newArray = userChoices.filter((userCuid) => userCuid !== cuid);
      return setUserChoices(newArray);
    }

    return setUserChoices([...userChoices, cuid]);
  };

  const checked = (cuid) => {
    return userChoices.includes(cuid);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box mb={3.3}>
            <QuestionTitle>{questionItem.title}</QuestionTitle>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box mb={2}>
            <Grid container>
              {choices.map((choice) => (
                <Grid item xs={12} sm={12} md={6} lg={6} key={choice.cuid}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={answerItem}
                        checked={checked(choice.cuid)}
                        onChange={() => handleChange(choice.cuid)}
                        style={{ color: '#201b02' }}
                      />
                    }
                    label={choice.label}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomButton
            color="secondary"
            variant="contained"
            disabled={loading || !userChoices.length || answerItem}
            onClick={() =>
              handleSubmitAnswer({
                question_item_id: questionItem.id,
                answer: { items: userChoices },
              })
            }
          >
            {textSubmitButton}
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
}

MultiChoiceQuestion.propTypes = {
  questionItem: PropTypes.object.isRequired,
  handleSubmitAnswer: PropTypes.func.isRequired,
  answerItem: PropTypes.object.isRequired,
  textSubmitButton: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
