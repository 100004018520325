import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  loading: false,
  errors: [],
};

export const { Types, Creators } = createActions({
  forgotRequest: ['payload'],
  forgotSuccess: [],
  forgotFailure: ['errors'],
});

function forgotRequest(state) {
  return { ...state, loading: true };
}

function forgotSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function forgotFailure(state, { errors }) {
  return {
    ...state,
    loading: false,
    errors,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.FORGOT_REQUEST]: forgotRequest,
  [Types.FORGOT_SUCCESS]: forgotSuccess,
  [Types.FORGOT_FAILURE]: forgotFailure,
});
