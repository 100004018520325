import styled from 'styled-components';
import { DialogTitle } from '@material-ui/core';

export const StyledCloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  .MuiTypography-subtitle1 {
    font-size: 16px;
    font-weight: 700;
    color: #2e3133;
  }
`;
