import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { Box, Button, Grid } from '@material-ui/core';
import Countdown from 'react-countdown';
import { Timer } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import Http from '~/services/api';
import {
  CountDownLabel,
  CountDownNumber,
  Label,
  GridCountDown,
  CountdownDiv,
  CountdownButton,
  CustomTimerIcon,
} from './styles';

export default function CountdownComponent({ id }) {
  const [countdown, setCountdown] = useState({});
  const { darkMode } = useSelector((state) => state.darkMode);

  useEffect(() => {
    (async () => {
      const { data } = await Http.get(`/countdowns/${id}`);
      setCountdown(data.countdown);
    })();
  }, [id]);

  const getLocalDateTime = () => {
    const currentDate = new Date(countdown.end_time);
    return new Date(currentDate.valueOf());
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <Grid container direction="column" alignItems="flex-start">
        <GridCountDown item xs={12} sm={12} lg={12}>
          <Grid container alignItems="flex-start" justify="center">
            <Grid item xs={8} md={10}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <CustomTimerIcon darkMode={darkMode} />
                <Label
                  darkMode={darkMode}
                  variant="body2"
                  className="mr-16 mr-0-mobile"
                >
                  EVENTO COMEÇA EM:
                </Label>
              </div>
            </Grid>
            <Grid item sm={10}>
              <Box
                width={1}
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent="center"
                alignItems="center"
                py={1.5}
                style={{ 'padding-bottom': '5px' }}
              >
                <Grid
                  container
                  spacing={1}
                  component={Box}
                  justify="flex-start"
                  width={1}
                  style={{ 'max-width': '300px' }}
                >
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      height={1}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <CountdownButton>
                            {`${days}`.padStart(2, '0')}
                          </CountdownButton>
                        </Grid>
                        <Grid item xs={12}>
                          <CountDownLabel variant="subtitle2">
                            dias
                          </CountDownLabel>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <CountdownButton>
                            {`${hours}`.padStart(2, '0')}
                          </CountdownButton>
                        </Grid>
                        <Grid item xs={12}>
                          <CountDownLabel variant="subtitle2">
                            horas
                          </CountDownLabel>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <CountdownButton>
                            {`${minutes}`.padStart(2, '0')}
                          </CountdownButton>
                        </Grid>
                        <Grid item xs={12}>
                          <CountDownLabel variant="subtitle2">
                            minutos
                          </CountDownLabel>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <CountdownButton>
                            {`${seconds}`.padStart(2, '0')}
                          </CountdownButton>
                        </Grid>
                        <Grid item xs={12}>
                          <CountDownLabel variant="subtitle2">
                            segundos
                          </CountDownLabel>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </GridCountDown>
      </Grid>
    );
  };

  return (
    <Box width={1} display="flex" justifyContent="center">
      {countdown && countdown.end_time && (
        <Countdown date={getLocalDateTime()} renderer={renderer} />
      )}
    </Box>
  );
}

CountdownComponent.propTypes = {
  id: Proptypes.number.isRequired,
};
