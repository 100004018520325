import styled from 'styled-components';
import { Paper, Typography, Button } from '@material-ui/core';

export const ResourcePaper = styled(Paper)`
  padding: 30px 34px;
  background: #edecec;
  box-shadow: none;
`;

export const ResourceTitle = styled(Typography)`
  font-weight: bold;
  font-size: 27px;
  margin-bottom: 15px;
  color: #333637;
`;

export const ResourceText = styled(Typography)`
  font-size: 15px;
  line-height: 17px;
  margin-bottom: 22px;
`;

export const ResourceButton = styled(Button)`
  font-weight: bold;
  color: white;
  min-width: 109px;
  text-transform: uppercase;
  line-height: 29px;
  font-size: 16px;
`;
