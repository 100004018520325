import React from 'react';
import { Box, Container } from '@material-ui/core';
import Proptypes from 'prop-types';
import { useSelector } from 'react-redux';
import StandardModal from '~/pages/Lobby/components/Modal';
import {
  StyledAlert,
  StyledBox,
  StyledButtonLogout,
} from '~/pages/Lobby/components/UpgradedModal/styles';

export default function UpgradedModal({ handleLogout }) {
  const { params: info } = useSelector((state) => state.modal);

  return (
    <StandardModal
      isOpened
      maxWidth="sm"
      showDividers={false}
      slideTo="left"
      fullWidth
      hideCloseButton
      actionElements={
        <StyledBox display="flex" alignContent="center" p={2}>
          <StyledButtonLogout
            variant="contained"
            size="large"
            color="secondary"
            onClick={handleLogout}
          >
            Sair
          </StyledButtonLogout>
        </StyledBox>
      }
    >
      <Box
        width={1}
        className="h-100"
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        pt={5}
      >
        <Container maxWidth="sm">
          <StyledAlert severity="success">
            Parabéns você já é {info.tag}. Faça seu login novamente para
            atualizar seu dashboard
          </StyledAlert>
        </Container>
      </Box>
    </StandardModal>
  );
}

UpgradedModal.propTypes = {
  handleLogout: Proptypes.func.isRequired,
};
