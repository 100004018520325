import styled from 'styled-components';
import { Button, Grid, Box } from '@material-ui/core';
import bgDark from '~/assets/images/bgDark.png';
import bgWhite from '~/assets/images/bgWhite.png';
import bilheteDark from '~/assets/svgs/bilhete_dark.svg';
import bilheteWhite from '~/assets/svgs/bilhete_white.svg';

export const Container = styled(Grid)`
  background-image: url(${(props) => (props.darkMode ? bgDark : bgWhite)});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100%;
  transition: 0.6s background-image ease-in;

  @media (max-width: 600px) {
    .mobilePadding {
      padding: 85px 0;
    }
  }
`;

export const ItemBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const LoginForm = styled(Box)`
  background-image: url(${(props) =>
    props.darkMode ? bilheteWhite : bilheteDark});
  background-size: 95% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 85px;
  padding-right: 50px;
  max-width: 550px;
  margin: auto;
  transition: 0.6s background-image ease-in;

  .forgotMyToken {
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    color: ${(props) => (props.darkMode ? '#5b5b5b' : '#707070')};
  }

  @media (max-width: 600px) {
    padding-left: 50px;

    .forgotMyToken {
      font-size: 0.8rem;
    }
  }

  .textDarkOrWhite {
    color: ${(props) => (props.darkMode ? '#5b5b5b' : '#707070')};
    font-size: 0.9rem;
    font-weight: 600;
  }
`;

export const SubmitButton = styled(Button)`
  background-color: ${(props) => (props.darkMode ? '#262626' : '#cccccc')};
  color: ${(props) => (props.darkMode ? '#ffffff' : '#707070')};
  width: 170px;
  font-size: 1rem;
  font-weight: 600;

  svg {
    font-size: 35px !important;
  }

  &.MuiButton-text {
    padding: 2px 0 !important;
  }

  .MuiButton-label {
    justify-content: flex-start;
    margin-left: 15px;
  }

  &:hover {
    background-color: ${(props) => (props.darkMode ? '#000' : '#fff')};
  }

  @media (max-width: 600px) {
    width: 150px;
    height: 35px;
  }
`;

export const InputGrid = styled(Grid)`
  .inputDarkLightMode {
    background-color: ${(props) => (props.darkMode ? '#262626' : '#cccccc')};
    border-radius: 5px;
    border: 0;

    input {
      padding: 10px 8px !important;
      color: ${(props) => (props.darkMode ? '#ffffff' : '#707070')};
      font-weight: 500;
      font-size: 1rem;

      .MuiIconButton-root {
        color: ${(props) => (props.darkMode ? '#ffffff' : '#707070')};
      }
    }
  }

  .adornmentIcon {
    color: ${(props) => (props.darkMode ? '#ffffff' : '#707070')};
  }

  p {
    transition: 0.6s ease-in;
    background-color: ${(props) => (props.darkMode ? '#cccccc' : '#262626')};
    font-size: 16px;
    color: ${(props) => (props.darkMode ? '#5b5b5b' : '#707070')};
    margin: 0;
  }
`;
