import React from 'react';
import PropTypes from 'prop-types';

import { Close } from '@material-ui/icons';

import {
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  TextField,
  Grid,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import ValidationSchema from './index.schema';

import {
  StyledCloseIcon,
  StyledDialog,
  StyledDialogTitle,
  InputGrid,
  SubmitButton,
} from './styles';

import { Creators as ForgotActions } from '~/store/modules/forgot/ducks';

export default function ForgotToken({ isOpen, handleCloseModal, darkMode }) {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: ({ email }) => {
      dispatch(ForgotActions.forgotRequest({ email }));
      formik.resetForm();
      handleCloseModal();
    },
  });

  return (
    <StyledDialog
      darkMode={darkMode}
      onClose={handleCloseModal}
      maxWidth="sm"
      fullWidth
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      PaperProps={{
        style: {
          backgroundColor: darkMode ? '#848483' : '#cccccc',
          color: darkMode ? '#efefee' : '#5b5b5b',
          boxShadow: 'true',
        },
      }}
    >
      <StyledDialogTitle onClose={handleCloseModal} align="center">
        <Typography
          variant="subtitle1"
          className="forgotMyToken"
          component="span"
          darkMode={darkMode}
        >
          Esqueci o meu token
        </Typography>
        <StyledCloseIcon>
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <Close size="18" />
          </IconButton>
        </StyledCloseIcon>
      </StyledDialogTitle>
      <DialogContent>
        <Grid container justify="center">
          <InputGrid item darkMode={darkMode}>
            <TextField
              error={formik.errors.email}
              helperText={formik.errors.email}
              className="inputDarkLightMode"
              placeholder="Digite o seu email"
              variant="outlined"
              value={formik.values.email}
              name="email"
              onChange={formik.handleChange}
            />
          </InputGrid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SubmitButton onClick={formik.handleSubmit} darkMode={darkMode}>
          Enviar
        </SubmitButton>
      </DialogActions>
    </StyledDialog>
  );
}

ForgotToken.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  darkMode: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
