import { createMuiTheme } from '@material-ui/core/styles';

import palette from './colors';
import typography from './typography';

export default createMuiTheme({
  typography,
  palette: {
    ...palette,
  },
});
