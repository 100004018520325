import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  loading: false,
  errors: [],
};

export const { Types, Creators } = createActions({
  presenceRequest: ['payload'],
  presenceSuccess: ['payload'],
  presenceFailure: ['errors'],
});

function presenceRequest(state) {
  return { ...state, loading: true };
}

function presenceSuccess(state) {
  return {
    ...state,
    loading: false,
  };
}

function presenceFailure(state, { errors }) {
  return {
    ...state,
    loading: false,
    errors,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.PRESENCE_REQUEST]: presenceRequest,
  [Types.PRESENCE_SUCCESS]: presenceSuccess,
  [Types.PRESENCE_FAILURE]: presenceFailure,
});
