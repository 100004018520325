import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  loading: false,
  answers: [],
};

export const { Types, Creators } = createActions({
  answersListRequestSuccess: ['payload'],
  answersListRequestFailure: [],
  answersListRequest: ['payload'],
  answerSaveRequestSuccess: [],
  answerSaveRequestFailure: [],
  answerSaveRequest: ['payload'],
  launchAnswerRequest: ['payload'],
});

function answersListRequest(state) {
  return { ...state, loading: true };
}

function answersListRequestSuccess(state, { payload }) {
  return { ...state, loading: false, answers: payload };
}

function answersListRequestFailure(state) {
  return { ...state, loading: false };
}

function answerSaveRequest(state) {
  return { ...state, loading: true };
}

function answerSaveRequestSuccess(state) {
  return { ...state, loading: false };
}

function answerSaveRequestFailure(state) {
  return { ...state, loading: false };
}

function launchAnswerRequest(state) {
  return { ...state, loading: true };
}

export default createReducer(INITIAL_STATE, {
  [Types.ANSWERS_LIST_REQUEST]: answersListRequest,
  [Types.ANSWERS_LIST_REQUEST_SUCCESS]: answersListRequestSuccess,
  [Types.ANSWERS_LIST_REQUEST_FAILURE]: answersListRequestFailure,
  [Types.ANSWER_SAVE_REQUEST]: answerSaveRequest,
  [Types.ANSWER_SAVE_REQUEST_SUCCESS]: answerSaveRequestSuccess,
  [Types.ANSWER_SAVE_REQUEST_FAILURE]: answerSaveRequestFailure,
  [Types.LAUNCH_ANSWER_REQUEST]: launchAnswerRequest,
});
