export default {
  primary: {
    main: '#00080a',
    contrastText: '#fff',
  },
  secondary: {
    main: '#3fb8ed',
    contrastText: '#000000',
  },
  black: {
    main: '#353535',
    contrastText: '#DADEE2',
  },
  text: {
    primary: '#DADEE2',
    secondary: '#FFFFFF',
    disabled: '#737B87',
  },
  background: '#dadee2',
};
