import React from 'react';
import { ToastBox, ToastIcon, ToastText } from './styles';

// TODO Adicionar icone definido pelo pessoal de Design
export default function PointsToast({ closeToast, toastProps, points }) {
  return (
    <ToastBox>
      <ToastIcon>
        {points}
      </ToastIcon>
      <ToastText variant="span">
        <b>Você acabou de ganhar {points}</b>
      </ToastText>
    </ToastBox>
  );
}
