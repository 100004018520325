import { call, put, all, takeLatest } from 'redux-saga/effects';
import api from '~/services/api';

import { Creators as PresencesActions, Types as PresencesTypes } from './ducks';

export function* presenceRequest({ payload }) {
  try {
    yield call(api.post, '/presences', payload);
    yield put(PresencesActions.presenceSuccess());
  } catch (error) {
    yield put(PresencesActions.presenceFailure(error));
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(PresencesTypes.PRESENCE_REQUEST, presenceRequest),
  ]);
}
