import styled from 'styled-components';
import { Box, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export const StyledButtonLogout = styled(Button)`
  background-color: #00a574;
  justifycontent: center;
  color: #ffffff;
  :hover {
    background-color: #004e45;
  }
`;

export const StyledAlert = styled(Alert)`
  font-weight: bold;
`;

export const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
