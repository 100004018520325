import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { styles } from './styles';

export default function SingleChoice({ questionItem }) {
  const temp = document.createElement('div');
  temp.innerHTML = questionItem.title;
  const title = temp.textContent || temp.innerText;

  const checkAnswer = (questionId) => {
    const choosenAnswer = questionItem.answer[0].answer.item === questionId;
    return choosenAnswer ? 'x' : '  ';
  };

  return (
    <View>
      <Text style={styles.question}>{title}</Text>
      {questionItem.answer.length ? (
        <>
          {questionItem.details.choices.map((question) => (
            <Text key={question.cuid} style={styles.text}>
              ({checkAnswer(question.cuid)}) {question.label}
            </Text>
          ))}
        </>
      ) : (
        <Text style={styles.text}>Não Respondido</Text>
      )}
    </View>
  );
}

SingleChoice.propTypes = {
  questionItem: PropTypes.object.isRequired,
};
