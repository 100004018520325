export default {
  fontFamily: 'Roboto',
  h1: {
    fontSize: 56,
    fontWeight: 700,
    color: '#353535',
  },
  h2: {
    fontSize: 45,
    fontWeight: 700,
    color: '#353535',
  },
  h3: {
    fontSize: 33,
    fontWeight: 400,
    color: '#333637',
    textAlign: "center",
  },
  h4: {
    fontSize: 20,
    fontWeight: 400,
    color: '#333637',
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 700,
    color: '#EAEAEA',
  },
  subtitle2: {
    fontSize: 30,
    fontWeight: 300,
    color: '#262626',
  },
  body1: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '25px',
    color: '#707070',
  },
  body2: {
    fontSize: 15,
    fontWeight: 100,
    color: '#EAEAEA',
    lineHeight: '20px',
  },
  caption: {
    fontSize: 27,
    fontWeight: 300,
    color: '#FFFFFF',
  },
  button: {
    fontWeight: 400,
    textTransform: 'none',
  },
};
