import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  points: '',
  loading: false,
  errors: [],
};

export const { Types, Creators } = createActions({
  pointRequest: ['payload'],
  success: ['payload'],
  failure: ['errors'],
});

function pointRequest(state) {
  return { ...state, loading: true };
}

function success(state, { payload }) {
  return {
    ...state,
    points: payload,
    loading: false,
  };
}

function failure(state, { errors }) {
  return {
    ...state,
    loading: false,
    errors,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.POINT_REQUEST]: pointRequest,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
});
