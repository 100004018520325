import styled from 'styled-components';
import { Box, Paper } from '@material-ui/core';

export const WarmupRoomsPaper = styled(Paper)`
  background: #edecec !important;
  border: none;
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem;

  @media (max-width: 600px) {
    padding: 0;
  }
`;
