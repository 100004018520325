import * as yup from 'yup';

export default function createYupSchema(fields = []) {
  const mountSchema = () => {
    const schema = {};
    fields.forEach((field) => {
      schema[field.cuid] = yup.string().required('Campo obrigátorio');
    });

    return schema;
  };

  const schema = yup.object().shape(mountSchema(fields));

  return schema;
}
