import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, RadioGroup, FormControlLabel } from '@material-ui/core';
import { QuestionTitle, CustomRadio, CustomButton } from './styles';

export default function SingleChoiceQuestion({
  questionItem,
  handleSubmitAnswer,
  answerItem,
  textSubmitButton,
  loading,
}) {
  const { choices = [] } = questionItem.details;
  const [userChoice, setUserChoice] = useState('');

  useEffect(() => {
    if (answerItem) {
      const { answer } = answerItem;
      setUserChoice(answer.item);
    }
  }, [answerItem]);

  const handleChange = (event) => {
    setUserChoice(event.target.value);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Box mb={3.3}>
            <QuestionTitle>{questionItem.title}</QuestionTitle>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box mb={2}>
            <RadioGroup
              name="choice"
              value={userChoice}
              onChange={(e) => handleChange(e)}
            >
              {choices.map((choice) => (
                <FormControlLabel
                  key={choice.cuid}
                  value={choice.cuid}
                  disabled={answerItem}
                  control={<CustomRadio />}
                  label={choice.label}
                />
              ))}
            </RadioGroup>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomButton
            color="secondary"
            variant="contained"
            disabled={loading || !userChoice || answerItem}
            onClick={() =>
              handleSubmitAnswer({
                question_item_id: questionItem.id,
                answer: { item: userChoice },
              })
            }
          >
            {textSubmitButton}
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
}

SingleChoiceQuestion.propTypes = {
  questionItem: PropTypes.object.isRequired,
  handleSubmitAnswer: PropTypes.func.isRequired,
  answerItem: PropTypes.object.isRequired,
  textSubmitButton: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
