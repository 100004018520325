import { all } from 'redux-saga/effects';

import authSagas from './auth/sagas';
import forgotSagas from './forgot/sagas';
import conferenceSagas from './conference/sagas';
import answersSagas from './answer/sagas';
import pointsSagas from './points/sagas';
import rankingSagas from './ranking/sagas';
import presenceSagas from './presence/sagas';

export default function* rootSaga() {
  return yield all([
    authSagas(),
    forgotSagas(),
    conferenceSagas(),
    answersSagas(),
    pointsSagas(),
    rankingSagas(),
    presenceSagas(),
  ]);
}
