import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  current: {},
  components: [],
  loading: false,
  toggling: false,
};

export const { Types, Creators } = createActions({
  componentsRequest: ['payload'],
  fetchConferenceConfigRequest: [],
  fetchConferenceConfigSuccess: ['payload'],
  fetchConferenceConfigFailure: [],
  componentsRequestSuccess: ['payload'],
  componentsRequestFailure: [],
  componentToggle: ['payload'],
  saveLaunchPlanAnswer: ['payload'],
});

function fetchConferenceConfigRequest(state) {
  return { ...state, loading: true };
}

function fetchConferenceConfigSuccess(state, { payload }) {
  return { ...state, loading: false, current: payload };
}

function fetchConferenceConfigFailure(state) {
  return { ...state, loading: false };
}

function componentsRequest(state) {
  return { ...state, loading: true };
}

function componentsRequestSuccess(state, { payload }) {
  return { ...state, loading: false, components: payload };
}

function componentsRequestFailure(state) {
  return { ...state, loading: false };
}

function componentToggle(state, { payload }) {
  const newComponents = state.components.map((component) => {
    if (component.id === payload.id) {
      return { ...component, active: payload.active };
    }
    return component;
  });

  return { ...state, components: newComponents };
}

function saveLaunchPlanAnswer(state, { payload }) {
  const newComponents = state.components.map((component) => {
    if (component.id === payload.componentId) {
      component.launch_plan.items.map((item) => {
        if (item.id === payload.itemId) {
          item.answer = [{ answer: payload.answer }];
        }
        return item;
      });
    }

    return component;
  });

  return { ...state, components: newComponents };
}

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_CONFERENCE_CONFIG_REQUEST]: fetchConferenceConfigRequest,
  [Types.FETCH_CONFERENCE_CONFIG_SUCCESS]: fetchConferenceConfigSuccess,
  [Types.FETCH_CONFERENCE_CONFIG_FAILURE]: fetchConferenceConfigFailure,
  [Types.COMPONENTS_REQUEST]: componentsRequest,
  [Types.COMPONENTS_REQUEST_SUCCESS]: componentsRequestSuccess,
  [Types.COMPONENTS_REQUEST_FAILURE]: componentsRequestFailure,
  [Types.COMPONENT_TOGGLE]: componentToggle,
  [Types.SAVE_LAUNCH_PLAN_ANSWER]: saveLaunchPlanAnswer,
});
