import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  Creators as ConferenceActions,
  Types as ConferenceTypes,
} from './ducks';

export function* componentsRequest() {
  try {
    const { data } = yield call(api.get, `/components`);
    yield put(ConferenceActions.componentsRequestSuccess(data.components));
  } catch {
    toast.error('Erro ao carregar o Lobby');
    yield put(ConferenceActions.componentsRequestFailure());
  }
}

export function* fetchConferenceConfig() {
  try {
    const { data } = yield call(api.get, `/conferenceconfig`);
    yield put(
      ConferenceActions.fetchConferenceConfigSuccess(data.conferenceConfig)
    );
  } catch {
    toast.error('Erro ao carregar o Lobby');
    yield put(ConferenceActions.fetchConferenceConfigFailure());
  }
}

export default function* rootSaga() {
  return yield all([
    takeLatest(ConferenceTypes.COMPONENTS_REQUEST, componentsRequest),
    takeLatest(
      ConferenceTypes.FETCH_CONFERENCE_CONFIG_REQUEST,
      fetchConferenceConfig
    ),
  ]);
}
