import { call, put, all, takeLatest } from 'redux-saga/effects';
import api from '~/services/api';

import { Creators as RankingActions, Types as RankingTypes } from './ducks';

export function* rankingRequest() {
  try {
    const { data } = yield call(api.get, '/userRanking');
    yield put(RankingActions.rankingSuccess(data));
  } catch (error) {
    yield put(RankingActions.rankingFailure(error));
  }
}

export default function* rootSaga() {
  return yield all([takeLatest(RankingTypes.RANKING_REQUEST, rankingRequest)]);
}
