import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Box, Grid, TextField } from '@material-ui/core';
import createYupSchema from './schemas/multiAswer';
import { TextFieldCss, QuestionTitle, CustomButton } from './styles';

export default function MultiAnswerQuestion({
  questionItem,
  handleSubmitAnswer,
  answerItem,
  textSubmitButton,
  loading,
}) {
  const { choices } = questionItem.details;

  const textFieldClass = TextFieldCss();
  const initialValues = (array = []) => {
    const values = {};
    array.forEach((choice) => {
      values[choice.cuid] = '';
    });

    return values;
  };

  const formik = useFormik({
    initialValues: initialValues(choices),
    validateOnChange: true,
    validationSchema: createYupSchema(choices),
    onSubmit: async (values) => {
      const isValid = await formik.validateForm();
      if (isValid) {
        const entries = Object.entries(values);
        const items = entries.map((item) => {
          const [cuid, value] = item;
          return { cuid, answer: value };
        });
        handleSubmitAnswer({
          question_item_id: questionItem.id,
          answer: { items },
        });
        return items;
      }
      return false;
    },
  });

  useEffect(() => {
    if (answerItem) {
      choices.forEach((choiceItem) => {
        const choiceAnswer = answerItem.answer.items.find((item) => {
          return item.cuid === choiceItem.cuid;
        });

        formik.setFieldValue(choiceItem.cuid, choiceAnswer.answer, true);
      });
    }
  }, [answerItem]);

  const onChangeInput = ({ name, value }) => {
    formik.setFieldValue(name, value, true);
  };

  const hasError = (fieldName) =>
    formik.touched[fieldName] && !!formik.errors[fieldName];

  const fieldsEmpty = () => {
    const entries = Object.entries(formik.values);
    const empties = entries.find((item) => {
      const [, value] = item;
      return value === '';
    });
    return !!empties;
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Box mb={3.3}>
            <QuestionTitle> {questionItem.title} </QuestionTitle>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box mb={2}>
            {choices.map((answer) => (
              <TextField
                className={textFieldClass.root}
                variant="outlined"
                name={answer.cuid}
                value={formik.values[answer.cuid]}
                label={answer.label}
                error={hasError(answer.cuid)}
                disabled={answerItem}
                onChange={({ target }) => onChangeInput(target)}
                fullWidth
                size="small"
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomButton
            color="secondary"
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={loading || answerItem || fieldsEmpty()}
          >
            {textSubmitButton}
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
}

MultiAnswerQuestion.propTypes = {
  questionItem: PropTypes.object.isRequired,
  handleSubmitAnswer: PropTypes.func.isRequired,
  answerItem: PropTypes.object.isRequired,
  textSubmitButton: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
