import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Box, Grid, TextField } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { TextFieldCss } from '~/pages/Lobby/components/Questions/styles';
import { CustomButton } from './styles';

export default function MultiAnswerQuestion({
  launchItem,
  handleSubmitAnswer,
  answerItem,
  componentId,
}) {
  const { choices } = launchItem.details;

  const textFieldClass = TextFieldCss();
  const initialValues = (array = []) => {
    const values = {};
    array.forEach((choice) => {
      values[choice.cuid] = '';
    });

    return values;
  };

  const formik = useFormik({
    initialValues: initialValues(choices),
    onSubmit: async (values) => {
      const entries = Object.entries(values);
      const items = entries.map((item) => {
        const [cuid, value] = item;
        return { cuid, answer: value };
      });
      handleSubmitAnswer({
        launchPlanItemId: launchItem.id,
        pointValue: launchItem.point_value,
        answer: { items },
        componentId,
      });
      return items;
    },
  });

  useEffect(() => {
    if (answerItem.length) {
      choices.forEach((choiceItem) => {
        const choiceAnswer = answerItem[0].answer.items.find((item) => {
          return item.cuid === choiceItem.cuid;
        });

        formik.setFieldValue(choiceItem.cuid, choiceAnswer.answer, true);
      });
    }
  }, [answerItem]);

  const onChangeInput = ({ name, value }) => {
    formik.setFieldValue(name, value, true);
  };

  const getEditorTextFormated = (content) => {
    const conferenceKeyValue = htmlToDraft(content);
    const editorState = ContentState.createFromBlockArray(
      conferenceKeyValue.contentBlocks
    );

    return EditorState.createWithContent(editorState);
  };

  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Box>
            <Editor
              toolbarHidden
              editorState={getEditorTextFormated(launchItem.title)}
              editorStyle={{ pointerEvents: 'none' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box mb={2}>
            {choices.map((answer) => (
              <TextField
                className={textFieldClass.root}
                variant="outlined"
                name={answer.cuid}
                value={formik.values[answer.cuid]}
                label={answer.label}
                onChange={({ target }) => onChangeInput(target)}
                fullWidth
                size="small"
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <CustomButton
            variant="contained"
            color="secondary"
            onClick={formik.handleSubmit}
          >
            SALVAR
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
}

MultiAnswerQuestion.propTypes = {
  launchItem: PropTypes.object.isRequired,
  handleSubmitAnswer: PropTypes.func.isRequired,
  answerItem: PropTypes.object.isRequired,
  componentId: PropTypes.number.isRequired,
};
