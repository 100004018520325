import React from 'react';
import { Box, Container } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import Proptypes from 'prop-types';
import StandardModal from '~/pages/Lobby/components/Modal';
import {
  StyledButtonCancel,
  StyledButtonCopy,
  StyledTextField,
} from '~/pages/Lobby/components/CopyAffiliateLinkModal/styles';

export default function CopyAffiliateLinkModal({
  handleCopy,
  handleCloseModal,
  link,
}) {
  return (
    <StandardModal
      title="CONVIDE SEUS AMIGOS"
      isOpened
      maxWidth="sm"
      showDividers={false}
      slideTo="left"
      fullWidth
      onClose={handleCloseModal}
      actionElements={
        <Box display="flex" p={2}>
          <Box px={2}>
            <StyledButtonCancel onClick={handleCloseModal}>
              Cancelar
            </StyledButtonCancel>
          </Box>
          <StyledButtonCopy
            variant="contained"
            size="large"
            color="secondary"
            onClick={handleCopy}
          >
            Copiar Link
          </StyledButtonCopy>
        </Box>
      }
    >
      <Box
        width={1}
        className="h-100"
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        pt={5}
      >
        <Container maxWidth="sm">
          <StyledTextField
            name="url"
            variant="outlined"
            value={link}
            readOnly
            disabled
            fullWidth
          />
          <Box mb={2}>
            <Alert severity="info">
              Convide seus amigos utilizando o link acima
            </Alert>
          </Box>
          <Box>
            <Alert severity="info">
              <p>
                Aqui você ganha 10 pontos a cada amigo que convidar e aceitar o
                seu convite (ou seja, o amigo que se inscrever e que confirmar a
                inscrição por e-mail); Logo, a gente recomenda que você convide
                o máximo de pessoas, tanto para acumular pontos, quanto para
                tê-las junto com você nesse Desafio.
              </p>
            </Alert>
          </Box>
        </Container>
      </Box>
    </StandardModal>
  );
}

CopyAffiliateLinkModal.propTypes = {
  handleCopy: Proptypes.func.isRequired,
  handleCloseModal: Proptypes.func.isRequired,
  link: Proptypes.string.isRequired,
};
