import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Creators as RankingActions } from '~/store/modules/ranking/ducks';
import {
  RankingBox,
  PointBox,
  ImageBox,
  NumberPoints,
  LabelPoints,
  TextGrid,
  TextTable,
  EmptyBox,
} from './style';

function UserRanking() {
  const dispatch = useDispatch();
  const { userPoints, rankingList } = useSelector((state) => state.ranking);

  useEffect(() => {
    dispatch(RankingActions.rankingRequest());
  }, []);

  const normalizeName = ({ username }) => {
    return username ? username.split('@')[0] : 'N/A';
  };

  const pointsSize = (points) => {
    if (points > 999) {
      return '26px';
    }

    return '36px';
  };

  return (
    <Box>
      <PointBox width="80%" mx="auto">
        <Grid
          container
          spacing={1}
          style={{ textAlign: 'left' }}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={3}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <ImageBox>
              <NumberPoints style={{ fontSize: pointsSize(userPoints) }}>
                {userPoints || 0}
              </NumberPoints>

              <LabelPoints>Pontos</LabelPoints>
            </ImageBox>
          </Grid>
          <TextGrid item xs={12} sm={9}>
            <Typography variant="h4">Você está indo muito bem!</Typography>
            <Typography variant="body1">
              Continue respondendo as perguntas e explorando o dashboard para
              ganhar pontos
            </Typography>
            <Typography
              variant="body2"
              style={{ fontWeight: '600', color: '#707070' }}
            >
              * suas atividades serão corrigidas pela equipe, e após isso, sua
              pontuação poderá ser alterada
            </Typography>
          </TextGrid>
        </Grid>
      </PointBox>

      <Box>
        <TextTable>
          A lista abaixo mostra os 100 maiores pontuadores! Se você não viu seu
          nome na lista, ainda dá tempo!
        </TextTable>
        <TextTable>
          <Typography
            variant="body2"
            style={{ fontWeight: '600', color: '#707070' }}
          >
            * O top 100 é atualizado a cada 5 minutos
          </Typography>
        </TextTable>
      </Box>

      <TableContainer component={RankingBox}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="left">Nome</TableCell>
              <TableCell align="right">Pontos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!rankingList.length &&
              rankingList.map((rankingItem, index) => (
                <TableRow key={rankingItem.id}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">
                    {normalizeName(rankingItem)}
                  </TableCell>
                  <TableCell align="right">
                    {rankingItem.total_points}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!rankingList.length && (
        <EmptyBox width={1} textAlign="center">
          Ranking não atualizado
        </EmptyBox>
      )}
    </Box>
  );
}

export default UserRanking;
