import styled, { createGlobalStyle } from 'styled-components';
import logoImg from '~/assets/images/flinsider_logo.png';
import palette from './theme/colors';
import 'react-toastify/dist/ReactToastify.css';

export const Logo = styled.div`
  background-image: url('${logoImg}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 216px;
  height: 103px;

  @media (max-width: 960px) {
    max-width: 260px;
  }

  @media (max-width: 600px) {
    max-width: calc(100% - 100px);
    width: 260px;
    margin-bottom: 1rem;
  }
`;

export const LogoVip = styled(Logo)`
  width: 372px;
  height: 92px;
`;

export const LogoLines = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 57px;
  margin: 0 40px;

  @media (max-width: 960px) {
    width: calc(100% - 40px);
    height: calc((100vw - 72px) / 13);
    height: 57px;
    margin: 30px 20px 0;
  }

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

export default createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
      font-family: 'Raleway', sans-serif !important;
    }

    .material-icons {
      font-family: 'Material Icons' !important;
    }

    html, body, #root {
     height: 100%;
    }

    body {
      background: #ffffff;
      color: #333637;
      font-weight: 400;
    }

    .Toastify {
      &__toast{
        border-radius: 5px;

        &--info{
          background: #2196f3;
        }

        &--success {
          background: #03CEA4;
        }

        &--warning {
          background: #ff9800;
        }

        &--error {
          background: #F46197;
        }
      }
    }

    .mt-20 {
      margin-top: 20px !important;
    }

    .mb-12 {
      margin-bottom: 12px !important;
    }

    .mb-20 {
      margin-bottom: 20px !important;
    }

    .mb-30 {
      margin-bottom: 30px !important;
    }

    .mb-40 {
      margin-bottom: 40px !important;
    }

    .mb-70 {
      margin-bottom: 70px !important;
    }

    .mb-100 {
      margin-bottom: 100px !important;
    }

    .mw-1088 {
      max-width: 1088px;
      margin: 0 auto;
    }

    .round-button {
      width: 100%;
      max-width: 1088px;
      min-height: 100px;
      border-radius: 10px;
      margin: 0 auto 60px;
      font-size: 57px;
      font-weight: 400;
      text-align: center;
      line-height: 1.2;

      &.large {
        font-size: 34px;
        max-width: 747px;
        padding: 45px;
        font-weight: 500;
      }

      &.small {
        text-align: left;
        font-size: 27px;
        font-weight: 500;
        padding: 19px 37px !important;
        margin-bottom: 40px;
        text-transform: uppercase;
        display: flex;
        justify-content: left;
        min-height: auto;

        @media (max-width: 960px) {
          font-size: 18px;
        }
      }

      &:not(.no-padding) {
        padding: 17px;
      }

      &:not(button):not(a) {
        background: ${palette.secondary.main};
        color: ${palette.secondary.contrastText};
      }

      &.gray {
        background: ${palette.primary.main};
        color: ${palette.primary.contrastText};
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),
        0px 2px 2px 0px rgba(0,0,0,0.14),
        0px 1px 5px 0px rgba(0,0,0,0.12);
      }

      &.black {
        background: ${palette.black.main};
        color: ${palette.black.contrastText};

        &:hover {
          background: #696868;
        }
      }

      @media (max-width: 960px) {
        font-size: 34px;
        margin: 0 auto 30px;
      }

      @media (max-width: 600px) {
        font-size: 28px;
        line-height: 1;
      }
    }

    button.round-button {
      display: block;
    }

    .black {
      background: ${palette.black.main};
      color: ${palette.black.contrastText};

      &:hover {
        background: #696868;
      }
    }

    .MuiAccordionSummary-content {
      margin: 0 !important;
      align-items: center;
    }


  .pointsToast .Toastify__toast--default {
    background-color: #000506;
    color: #fff;
    display: flex;

    @media (max-width: 600px) {
      margin: 0 1em;
    }
  }

  .pointsToast .Toastify__toast--default .Toastify__toast-body div:nth-child(1) {
    display: flex;
  }

  .pointsToast .Toastify__toast--default .Toastify__toast-body img:nth-child(1) {
    padding-right: 20px;
    height: 30px;
    margin-top: 12px;
  }

  .pointsToast .Toastify__toast--default .Toastify__toast-body p:nth-child(2) {
    font-size: 18px;
  }

  .Toastify__toast-container--top-right {
    top: 9em;

    @media (max-width: 600px) {
      top: 2em;
    }
  }
`;
