import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CustomButton } from './styles';

export default function MultiChoiceQuestion({
  launchItem,
  handleSubmitAnswer,
  answerItem,
  componentId,
}) {
  const { choices = [] } = launchItem.details;
  const [userChoices, setUserChoices] = useState([]);

  useEffect(() => {
    if (answerItem.length) {
      const { answer } = answerItem[0];
      setUserChoices(answer.items);
    }
  }, [answerItem]);

  const handleChange = (cuid) => {
    if (userChoices.includes(cuid)) {
      const newArray = userChoices.filter((userCuid) => userCuid !== cuid);
      return setUserChoices(newArray);
    }

    return setUserChoices([...userChoices, cuid]);
  };

  const checked = (cuid) => {
    return userChoices.includes(cuid);
  };

  const getEditorTextFormated = (content) => {
    const conferenceKeyValue = htmlToDraft(content);
    const editorState = ContentState.createFromBlockArray(
      conferenceKeyValue.contentBlocks
    );

    return EditorState.createWithContent(editorState);
  };

  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box>
            <Editor
              toolbarHidden
              editorState={getEditorTextFormated(launchItem.title)}
              editorStyle={{ pointerEvents: 'none' }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box mb={2}>
            <Grid container>
              {choices.map((choice) => (
                <Grid item xs={12} sm={12} md={6} lg={6} key={choice.cuid}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked(choice.cuid)}
                        onChange={() => handleChange(choice.cuid)}
                        style={{ color: '#201b02' }}
                      />
                    }
                    label={choice.label}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomButton
            variant="contained"
            color="secondary"
            onClick={() =>
              handleSubmitAnswer({
                launchPlanItemId: launchItem.id,
                pointValue: launchItem.point_value,
                answer: { items: userChoices },
                componentId,
              })
            }
          >
            SALVAR
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
}

MultiChoiceQuestion.propTypes = {
  launchItem: PropTypes.object.isRequired,
  handleSubmitAnswer: PropTypes.func.isRequired,
  answerItem: PropTypes.object.isRequired,
  componentId: PropTypes.number.isRequired,
};
