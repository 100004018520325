import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  ResourcePaper,
  ResourceTitle,
  ResourceText,
  ResourceButton,
} from './styles';

export default function Resources({
  resourcesListsComponents,
  handlePointClick,
}) {
  const handleDownloadFile = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      {resourcesListsComponents.length ? (
        <Box
          display="flex"
          width={1}
          flexDirection="column"
          justifyContent="flex-start"
        >
          {resourcesListsComponents.map((resourceListComponent) =>
            resourceListComponent.resource.items.map((resource) => (
              <Box width={1} mb={2} key={resource.id}>
                <ResourcePaper>
                  <ResourceTitle>{resource.title}</ResourceTitle>
                  <ResourceText>{resource.description}</ResourceText>
                  <ResourceButton
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => {
                      handleDownloadFile(resource.link);
                      handlePointClick(resource.id, 'resource');
                    }}
                  >
                    BAIXAR
                  </ResourceButton>
                </ResourcePaper>
              </Box>
            ))
          )}
        </Box>
      ) : (
        <Box mt={3} textAlign="center">
          <Typography>Nenhum material ativo no momento</Typography>
        </Box>
      )}
    </>
  );
}

Resources.propTypes = {
  resourcesListsComponents: PropTypes.array.isRequired,
  handlePointClick: PropTypes.func.isRequired,
};
