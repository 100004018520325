import { createReducer, createActions } from 'reduxsauce';

export const INITIAL_STATE = {
  userPoints: '',
  rankingList: [],
  loading: false,
  errors: [],
};

export const { Types, Creators } = createActions({
  rankingRequest: ['payload'],
  rankingSuccess: ['payload'],
  rankingFailure: ['errors'],
});

function rankingRequest(state) {
  return { ...state, loading: true };
}

function rankingSuccess(state, { payload }) {
  return {
    ...state,
    userPoints: payload.userPoints,
    rankingList: payload.rankingList,
    loading: false,
  };
}

function rankingFailure(state, { errors }) {
  return {
    ...state,
    loading: false,
    errors,
  };
}

export default createReducer(INITIAL_STATE, {
  [Types.RANKING_REQUEST]: rankingRequest,
  [Types.RANKING_SUCCESS]: rankingSuccess,
  [Types.RANKING_FAILURE]: rankingFailure,
});
