import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function Instructions({ conferenceConfig }) {
  const [faq, setFaq] = useState(EditorState.createEmpty());
  const [instructions, setInstructions] = useState(EditorState.createEmpty());

  const fields = {
    faq: (content) => setFaq(content),
    instructions: (content) => setInstructions(content),
  };

  useEffect(() => {
    if (conferenceConfig) {
      Object.entries(conferenceConfig).forEach(([key, value]) => {
        const conferenceKeyValue = htmlToDraft(value);
        const editorState = ContentState.createFromBlockArray(
          conferenceKeyValue.contentBlocks
        );
        const content = EditorState.createWithContent(editorState);

        fields[key](content);
      });
    }
  }, [conferenceConfig.faq, conferenceConfig.instructions]);

  return (
    <Grid>
      <Grid item xs={12} sm={12} md={12}>
        {conferenceConfig.faq || conferenceConfig.instructions ? (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <Box mb={4}>
                <Editor
                  editorState={instructions}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  toolbarHidden
                  readOnly
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box>
                <Editor
                  editorState={faq}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  toolbarHidden
                  readOnly
                />
              </Box>
            </Grid>
          </>
        ) : (
          <Box mt={3} textAlign="center">
            <Typography>Nenhuma informação até o momento</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

Instructions.propTypes = {
  conferenceConfig: PropTypes.object,
};

Instructions.defaultProps = {
  conferenceConfig: { instructions: '', faq: '' },
};
