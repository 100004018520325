import React from 'react';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';

import apiInterceptors from './services/interceptors';
import { store, persitor } from './store';

import Routes from './routes';
import history from './services/history';

import GlobalStyled from './styles/global';
import materialUiTheme from './styles/theme/material-ui-theme';
import theme from './styles/theme/defaultTheme';

function App() {
  apiInterceptors.onExpireToken();

  return (
    <Provider store={store}>
      <PersistGate persistor={persitor}>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <MaterialThemeProvider theme={materialUiTheme}>
              <CssBaseline />
              <GlobalStyled />
              <ToastContainer
                className="pointsToast"
                autoClose={3000}
                hideProgressBar
              />
              <Routes />
            </MaterialThemeProvider>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
