import { combineReducers } from 'redux';

import auth from './auth/ducks';
import forgot from './forgot/ducks';
import conference from './conference/ducks';
import user from './user/ducks';
import answers from './answer/ducks';
import points from './points/ducks';
import modal from './modal/ducks';
import ranking from './ranking/ducks';
import presence from './presence/ducks';
import darkMode from './darkMode/ducks';

export default combineReducers({
  auth,
  forgot,
  conference,
  user,
  answers,
  points,
  modal,
  ranking,
  presence,
  darkMode,
});
